import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { DashboardOutlined, PlayCircleFilled, ZoomIn } from '@material-ui/icons';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    overflow: 'hidden',
    // marginLeft: 5,
    // marginRight: 5
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    maxWidth: 120
  },
  iframe: {
    border: 0
  },
  longer: {
      height: '100vh',
      width: '100vh',
  },
}));

export default function Dashboard(props) {

const [state_embed_link, setEmbedLink] = useState('')
const [stateTitle, setTitle] = useState('')
const [statePgpValue, setPgpValue] = useState('')
const [stateTargetUrl, setTargetUrl] = useState('')
const [stateShowChart, setShowChart] = useState(false)



  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const ssoProvider = useRef(null);
  const targetUrl = useRef(null);
  const encryptedClaims = useRef(null);
  const helperForm = useRef(null);
  const stateForm = useRef(null);
  useEffect(() => {

    //submitHidden()
    submitCheckExist()


  }, [])


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const specialPaper = clsx(classes.paper, classes.longer);
  console.log('Check')

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }




  const handleSubmit = () => {
    fetch('https://taskforceapp.on.gooddata.com/gdc/account/customerlogin', {
      method: 'POST',
      headers: {
        'Content-Type':'application/json'
      },
      body: JSON.stringify({targetUrl: props.targetUrl, ssoProvier: "pgp.taskforce.app", pgpMessage: props.pgpValue  })


    })



  }


  const handleFormChange = () => {



  }

  const submitCheckExist = () =>{
    if(props.targetUrl){
      helperForm.current.submit()
    }else{
    fetch('/api/current_dash/', { headers:
  //fetch('http://localhost:8000/current_dash/', { headers:
  //fetch('https://briantaskforce.pythonanywhere.com/current_dash/', { headers:
    { Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
  }).then(res => res.json())
  .then(json => {
    if(json.embed_link){

      setEmbedLink(json.embed_link)
      setTitle(json.title)
      setPgpValue(json.pgpValue)
      setTargetUrl(json.targetUrl)
      setShowChart(true)



      stateForm.current.submit()
      //csv link here
      // app link here
      console.log("Embed Link "+json.embed_link)
      console.log("Target URL Link "+json.targetUrl)
      console.log("State Target URL Link "+json.stateTargetUrl)

    }
  });

    }


  }

  const submitHidden = () => {
   console.log("helper form " +helperForm.current);
   console.log("helper form " +props.targetUrl);
   console.log("helper form " +props.pgpValue);
   helperForm.current.submit();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <img className={classes.logo} src='tfwhite.png'></img>
          </Typography>


          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
            <ListItemLink href={props.title} >
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Visit your app" />
            </ListItemLink>
            {/* <ListItemLink href="#somedownload">
                <ListItemText primary="Download Your CSV" />
            </ListItemLink> */}
            <ListItem onClick={props.logout} button>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>

                <ListItemLink href="/add-query" button>
                <ListItemIcon>
                    <ZoomIn />
                </ListItemIcon>
                <ListItemText primary="Add a Query" />
                </ListItemLink>


                <ListItemLink href="/queries" button>
                <ListItemIcon>
                    <DashboardOutlined />
                </ListItemIcon>
                <ListItemText primary="Manage Existing Queries" />
                </ListItemLink>

                <ListItemLink href="/results" button>
                <ListItemIcon>
                    <PlayCircleFilled />
                </ListItemIcon>
                <ListItemText primary="View Results" />
                </ListItemLink>


        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid  style={{width:'100%', height: '100vh', overflow: 'hidden'}} container spacing={3}>
            {/* Chart */}
            {/* <Grid item xs={12} > */}
              {/* <Paper className={classes.longer}> */}

            <iframe id='embeddedGoodDataIframe' name='embeddedGoodDataIframe' className={classes.iframe} style={{width:'100%', height:'100%'}}></iframe>


            {/* src={props.embed_link} */}
             {/* GD Example */}
              <form
                id='helperForm'
                target='embeddedGoodDataIframe'
                method='post'
                action='https://taskforceapp.on.gooddata.com/gdc/account/customerlogin'
                ref={helperForm}
              >
              <input
                id="targetUrl"
                type="hidden"
                name="targetUrl"
                ref={targetUrl}
                value={props.targetUrl}
              />

              <input
                id="ssoProvider"
                type="hidden"
                name="ssoProvider"
                ref={ssoProvider}
                value="pgp.taskforce.app"
              />

              <input
                id='encryptedClaims'
                type='hidden'
                name='encryptedClaims'
                ref={encryptedClaims}
                value={props.pgpValue}
              />

              </form>
                <form
                id='stateForm'
                target='embeddedGoodDataIframe'
                method='post'
                action='https://taskforceapp.on.gooddata.com/gdc/account/customerlogin'
                ref={stateForm}
              >
              <input
                id="targetUrl"
                type="hidden"
                name="targetUrl"
                ref={targetUrl}
                value={stateTargetUrl}
              />
              <input
                id="ssoProvider"
                type="hidden"
                name="ssoProvider"
                ref={ssoProvider}
                value="pgp.taskforce.app"
              />

              <input
                id='encryptedClaims'
                type='hidden'
                name='encryptedClaims'
                ref={encryptedClaims}
                value={statePgpValue}
              />

              </form>



{/*              <div className="form-group container">
                <input id='tUrl' type="hidden" className="form-control" aria-describedby="targetUrl" onChange={this.handleFormChange.bind(this)} value={props.targetUrl} />
                <input id='sProv' type="hidden" className="form-control" aria-describedby="ssoProvider" value="pgp.taskforce.app" />
                <input id='pgpM' type="hidden" className="form-control" aria-describedby="pgpMessage" onChange={this.handleFormChange.bind(this)} value={props.pgpValue} />


              </div>

*/}

              {/* <iframe name='embeddedGoodDataIframe'></iframe> */}
{/*              <script type="text/javascript">
//                let targetUrl={props.targetUrl }
//                let ssoProvider= "pgp.taskforce.app"
                {/* let pgpMessage={props.pgpValue}



//              document.getElementById('targetUrl').value = targetUrl;
//              document.getElementById('ssoProvider').value = ssoProvider;
                document.getElementById('encryptedClaims').value = pgpMessage;
//              document.getElementById('helperForm').submit();

              </script>
*/}


              {/* </Paper> */}
            {/* </Grid> */}
            {/* Recent Deposits */}
            {/* <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <h1>Hello 1</h1>
              </Paper>
            </Grid> */}
            {/* Recent Orders */}
            {/* <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h1>Hello 2</h1>
              </Paper>
            </Grid> */}
          </Grid>
          <Box pt={4}>
            {/* <Copyright /> */}
          </Box>
        </Container>
      </main>
    </div>
  );
}
