import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Segment } from 'semantic-ui-react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  import Typography from '@material-ui/core/Typography';
  import ForgotPassForm from './ForgotPassForm'; 


class ForgotPass extends React.Component {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    state = {
      username: '',
      password: '',
      submitted: false,
      message: "Enter your email, and if its in our system we'll send you a message"
    };
    

    handle_reset = (e, data) => {
      e.preventDefault();
      console.log(process.env.REACT_APP_API_URL)
     // fetch('http://localhost:8000/reset-password/', {
      fetch('https://briantaskforce.pythonanywhere.com/reset-password/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(json => {
       
        this.setState({
          message:json.message,

        });
      });
    };
    render(){
      return (
        <div>
          {/* <h3></h3> */}
          <Typography component="h1" variant="h5">
          {this.state.message}
          </Typography>
          <ForgotPassForm handle_reset={this.handle_reset} /> 
        </div>
  
      );

    }

  }

export default ForgotPass