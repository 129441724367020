import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react'


function Nav(props) {


  const logged_out_nav = (
    // <ul>
    //     <li onClick={()=>props.display_form('login')}>login</li>
    // </ul>

  <Button  onClick={()=>props.display_form('login')} primary>Login</Button>
  );

  const logged_in_nav = (

    // <ul>
    //     <li onClick={props.handle_logout}>logout</li>
    // </ul>
<Button  onClick={props.handle_logout} secondary>Logout</Button>

  );

  return <div>{props.logged_in ? logged_in_nav : logged_out_nav}</div>;
}


export default Nav;

Nav.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};
