import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { Button, Checkbox, Segment, SegmentGroup, Form, Table, Label, Menu, Icon, Input } from 'semantic-ui-react'
import { Form as FinalForm, Field} from 'react-final-form';

const required = value => (value ? undefined : 'This field is required')
const initialState = {
        tagName: '',
        newTag:true,
        tag:{}

    }

class TagView extends React.Component {
    state = {

        tags: [],
        tagName: '',
        loaded: false,
        newTag:true,
        tag:{}


    }


handle_change = e => {
const name = e.target.name;
const value = e.target.value;

this.setState(prevstate => {
  const newState = { ...prevstate };
  newState[name] = value;
  return newState;
});

};


handle_addTag = (e,data) => {
  var tagMethod='';
  if(data.tagName=='') {
    alert("I'm sorry but you cannot add a blank tag.")

  }
  else {

    var stateTag=this.state.tag
    stateTag['tag_text']=this.state['tagName']
    this.setState({
      //tags: newArrayState,
      loaded: true,
      tag:stateTag
    })
    if(this.state.newTag) tagMethod='POST';
    else tagMethod='PUT';

    fetch('/api/tags/', {
      method: tagMethod,
      headers: {'Content-Type' : 'application/json',
              Authorization: `JWT ${localStorage.getItem('taskforceToken')}`

      },
      body: JSON.stringify(this.state.tag)
    })
    .then(res => {
      if(res.ok){
        alert("Tag saved!");
        var newArrayState = this.state.tags
        if (this.state.newTag==true){
          newArrayState.push({id:res.id, tag_text: this.state.tagName})

        }
        this.setState({tags:newArrayState})
        this.setState(initialState);

      }

      else
        return res.json()
    })

  }
    // var data = {'tagName': this.state.tagName }
}
createTag = () =>{
  this.setState({tag:{id:'',tag_text:''},newTag:true,tagName:''})
  //this.setState({tagName:'',newTag:true})
  //this.setState({newTag:true})
}
editTag = (tag,index)=>{
  this.setState({tag:tag,newTag:false,tagName:tag.tag_text},)
}

deleteTag = (tag,index) => {
  if (window.confirm("Are you sure you want to delete? The tag will be removed from any existing bill that contains this tag!")==true){
    var newArrayState = this.state.tags;
    newArrayState.splice(index,1)
    this.setState({tags: newArrayState})

    var deleteUrl ='/api/tags/'
      fetch(deleteUrl, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `JWT ${localStorage.getItem('taskforceToken')}`},
      body:JSON.stringify(tag)
    })
    .then(res => {
    if(res.ok) {

      alert("Tag Deleted")
        }
    else
      throw new Error(res)
    }).catch(e =>{
        alert("I'm sorry but the tag did not delete. Please contact support.")
    })
  }




}

componentDidMount() {
      fetch('/api/tags/', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
      Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
    })
    .then(res => {
    if(res.ok){
        console.log(res)
        return res.json();

    }
    else{
      this.setState(
        {loaded: false}
      )
      throw new Error(res)
    }
    }).then(json => {
      console.log(json)
      this.setState({
        tags: json,
        loaded: true
      });
    }).catch(e =>{
        console.log(e)
    })


}




    render(){
  if(this.state.loaded){
      return(



         <SegmentGroup>
            <Segment>
            <Menu>
              <Menu.Item href='/queries'>
              <Button basic color='black'> View Queries </Button>
              </Menu.Item>
              <Menu.Item href='/add-query'>
                <Button basic color='blue'>Add A Query</Button>
              </Menu.Item>
              <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item href='/bills'>
                <Button basic color='blue'>Bills</Button>
              </Menu.Item>

            </Menu>
            </Segment>
            <Segment>
              <Form>
                <Form.Field>
                  <label>Tag Text</label>
                  <Input id='tagName' name='tagName' value={this.state.tagName}  onChange={this.handle_change} />
                </Form.Field>
                <Button onClick={() => this.createTag()}>New Tag</Button>
                <Button onClick={e => this.handle_addTag(e, this.state)}>Save Tag</Button>
              </Form>

            </Segment>

            <Segment>
      <Table celled>
        <Table.Header>
        <Table.Row>
             <Table.Cell>
               Text
             </Table.Cell>
          </Table.Row>

        </Table.Header>
        <Table.Body>

        {this.state.tags.map((tag, index) =>
        <Table.Row key={index}>

          <Table.Cell>
          {tag.tag_text}
          </Table.Cell>
          <Table.Cell>
          <Button basic color='green' onClick={() => this.editTag(tag, index)}>Edit</Button>
          <Button basic color='red' onClick={() => this.deleteTag(tag, index)}>Delete</Button>
          </Table.Cell>
        </Table.Row>



        )}



        </Table.Body>

      </Table>


      </Segment>
            </SegmentGroup>

      )
    }else {

            return(



         <SegmentGroup>
            <Segment>
            <Menu>
              <Menu.Item href='/queries'>
              <Button basic color='black'> View Queries </Button>
              </Menu.Item>
              <Menu.Item href='/add-query'>
                <Button basic color='blue'>Add A Query</Button>
              </Menu.Item>
              <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item href='/bills'>
                <Button basic color='blue'>Bills</Button>

              </Menu.Item>

            </Menu>
            </Segment>
            <Segment>
              <Form>
                <Form.Field>
                  <label>Tag Text</label>
                  <Input  id='tagName' name='tagName' value={this.state.tagName}  onChange={this.handle_change} />
                </Form.Field>


              </Form>

            </Segment>
            </SegmentGroup>

      )






    }




    }







}
export default TagView
