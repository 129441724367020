import React, { useState, useEffect } from "react"; 
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { DashboardOutlined, PlayCircleFilled, ZoomIn } from '@material-ui/icons';
import { Button, Checkbox, Segment, SegmentGroup, Form, Table, Label, Menu, Icon } from 'semantic-ui-react'

class BillList extends React.Component { 
    state = {
        results: []
    
    }
    componentDidMount() {
        fetch('/api/bills/', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
        })
        .then(res => {
        if(res.ok){
            console.log(res)
            return res.json();  
            
        } 
        else{
        // localStorage.removeItem('taskforceToken');
        // this.setState({
        //     logged_in: false,

        //   })
          throw new Error(res)
        }
        }).then(json => {
          console.log(json)
          this.setState({
            results: json
          });
        }).catch(e =>{
            console.log(e)
        })

    }

    render(){

      return(

         <SegmentGroup>
            <Segment> 
            <Menu>
              <Menu.Item href='/queries'>
              <Button basic color='black'> View Queries </Button>
              </Menu.Item>
              <Menu.Item href='/add-query'>
                <Button basic color='blue'>Add A Query</Button>
              </Menu.Item>
                            <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item href='/bills'>
                <Button basic color='blue'>Bills</Button>
              </Menu.Item>
            </Menu>
            </Segment>

            <Segment>
           
            </Segment>
            
            <Segment> 
      <Table celled>
        <Table.Header>
        <Table.Row>
             <Table.Cell>
               Identifier
             </Table.Cell>
             <Table.Cell>
               Title
             </Table.Cell>
            <Table.Cell>
               Priority
             </Table.Cell>
                <Table.Cell>
               Tagged Category
             </Table.Cell>
             <Table.Cell>
               Jurisdiction
             </Table.Cell>
             <Table.Cell>
               Organization
             </Table.Cell>
             <Table.Cell>
              Session
          </Table.Cell>
          <Table.Cell>
          OpenStates URL 
          </Table.Cell>
          </Table.Row>

        </Table.Header>
        <Table.Body>
        {this.state.results.length ? this.state.results.map((result, index) => 
        <Table.Row key={index}> 
          <Table.Cell>
          {result.identifier}
          </Table.Cell>
          <Table.Cell>
          {result.title}
          </Table.Cell>
            <Table.Cell>
          {result.priority}
          </Table.Cell>
            <Table.Cell>
          {result.tags}
          </Table.Cell>
          <Table.Cell>
            {result.jurisdiction_classification} - {result.jurisdiction_name}  
          </Table.Cell>
          <Table.Cell>
          {result.from_organization_name}
          </Table.Cell>
          <Table.Cell>
          {result.session}
          </Table.Cell>
          <Table.Cell>
           <a href={result.openstates_url}>{result.openstates_url}</a> 
          </Table.Cell>
        </Table.Row>

        
        
        ) : <Table.Row><Table.Cell>There are no bills saved. Run a query to generate results, save a bill from the results.</Table.Cell></Table.Row>}



        </Table.Body>

      </Table>
      

      </Segment>
            </SegmentGroup>

      )




    }
    
    





}
export default BillList