import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button, Checkbox, Segment, SegmentGroup, Form, Table, Label, Menu, Icon } from 'semantic-ui-react'



class QueryList extends React.Component {
      state = {

        queries: [],
        username:'',

      }
      deleteQuery = (query,index) => {
        if (window.confirm('Are you sure you want to hide or delete the query ' +query.query_name + '??')){
          var newArrayState = this.state.queries;
          newArrayState.splice(index,1);
          this.setState({queries: newArrayState})
          var deleteUrl = '/api/delete_query/' + query.id.toString()
          fetch(deleteUrl, {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json', Authorization: `JWT ${localStorage.getItem('taskforceToken')}`},
            })
            .then(res => {
            if(res.ok) {
              console.log("Deleted")
              alert("Query Deleted")
               }
            else
              throw new Error(res)
            }).catch(e =>{
                console.log(e)
            })
          }
        }




    runThisQuery = (query) => {
        var runUrl = '/api/run_query/' + query.id.toString()
        fetch(runUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `JWT ${localStorage.getItem('taskforceToken')}`},
          })
          .then(res => {
          if(res.ok) {
            console.log("run")
            alert("Query Run Scheduled")

             }
          else
            throw new Error(res)
          }).catch(e =>{
              console.log(e)
          })
        }

    exportBills = (query) => {
        var runUrl = '/api/run_customer/' + query.id.toString()
        fetch(runUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `JWT ${localStorage.getItem('taskforceToken')}`},
          })
          .then(res => {
          if(res.ok) {
            console.log(res)
            alert("Bills Exported to S3")

             }
          else
            throw new Error(res)
          }).catch(e =>{
              console.log(e)
          })
        }
      // componentWillMount(){
      //   if(this.state.logged_in){
      //     fetch('/api/current_user/', { headers:
      //       //fetch('http://localhost:8000/current_user/', { headers:
      //       //fetch('https://briantaskforce.pythonanywhere.com/current_user/', { headers:
      //         { Authorization: `JWT ${localStorage.getItem('taskforceToken')}`} })
      //   .then(res => {
      //     if(res.ok)
      //       return res.json()
      //     else
      //       throw new Error(res)
      //   })
      //   .then(json =>{
      //     this.setState({username: json.username });

      //   })


      // }
      // }

      componentDidMount() {


        console.log(this.state.username)
        fetch('/api/queries/', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('taskforceToken')}`


          },
          }
        ).then(res => {
        console.log('hello there')
        if(res.ok)
          return res.json();
        else{
          // localStorage.removeItem('taskforceToken');
          // this.setState({
          //   logged_in: false,

          // })
          //should push back to login
          throw new Error(res)
        }
        }).then(json => {
          console.log(json)
          this.setState({
            queries: json
          });

        })
        .catch(error => {
          console.log('Load Error');
          console.log(error);

        });



      };




     // <Button type='button' onClick={props.handle_run_query}>Run all queries</Button>
    //  {queries &&
    //   queries.map((query, index) => (  key={index}
     // {query.id}  - {query.name} - {query.customer} - <button onClick={() => deleteQuery(query, index)} >Delete</button>
    render(){


    return(
      <SegmentGroup>
      <Segment>
      <Menu>
        <Menu.Item href='/results'>
        <Button basic color='black'> View Results </Button>
        </Menu.Item>
        <Menu.Item href='/add-query'>
          <Button basic color='blue'>Add A Query</Button>
        </Menu.Item>
              <Menu.Item href='/tags'>
        <Button basic color='blue'>Issue Tags</Button>
      </Menu.Item>
      <Menu.Item href='/bills'>
        <Button basic color='blue'>Bills</Button>
      </Menu.Item>
      </Menu>
      </Segment>
      <Segment>

      </Segment>
      <Segment>
      <Table celled>
        <Table.Header>
        <Table.Cell>
               Query Name
                </Table.Cell>
                <Table.Cell>
                Search Terms
                </Table.Cell>
                <Table.Cell>
                Customer
                </Table.Cell>

        </Table.Header>
        <Table.Body>
        {this.state.queries.length ? this.state.queries.map((q, index) =>

              <Table.Row key={index}>
                <Table.Cell>
                {q.query_name}
                </Table.Cell>
                <Table.Cell>
                {q.q}
                </Table.Cell>
                <Table.Cell>
                {q.customer}
                </Table.Cell>
                <Button basic color='green' type='button' onClick={() => this.runThisQuery(q)}>Run</Button>
                <Button basic color='yellow' type='button' href={`/add-query/${q.id}`}>Edit</Button>
                <Button basic color='red' type='button' onClick={() => this.deleteQuery(q, index)}>Hide</Button>
                <Button basic color='black' type='button' href={`/queries/results/${q.id}`} >View New Results</Button>
                <Button basic color='black' type='button' href={`/queries/results/${q.id}T`} >View Tracked  Results</Button>
                <Button basic color='black' type='button' href={`/queries/results/${q.id}U`} >View Un-Tracked  Results</Button>
                <Button basic color='blue' type='button' onClick={()=>this.exportBills(q)} >Export Bills</Button>
              </Table.Row>



        ): <Table.Row><Table.Cell>There are no queries. You need to add a query first.</Table.Cell></Table.Row> }



        </Table.Body>

      </Table>


      </Segment>
    </SegmentGroup>

    )

        }
      }





export default QueryList
