
import React, { Component } from 'react';
import Nav from './components/Nav'
import LoginForm from './components/LoginForm'
import SignupForm from './components/SignupForm'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SignIn from './components/SignIn';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import ForgotPass from './components/ForgotPass';
import ConfirmPass from './components/ConfirmPass';
import ConfirmPassword from './components/ConfirmPass';
import QueryForm from './components/QueryForm';
import QueryList from './components/QueryList';
import ResultList from './components/ResultList';
import BillView from './components/BillView';
import BillList from './components/BillList';
import QueryResultsView from './components/QueryResultsView';
import QueryDetail from './components/QueryDetail';
import TagView from './components/TagView';

class App extends Component {
  constructor(props){
    super(props);
    this.state ={
      displayed_form:'',
      logged_in: localStorage.getItem('taskforceToken') ? true : false,
      username: '',
      hasError: false,
      showChart: false
    };

  }
  componentDidMount(){
    console.log(localStorage.getItem('taskforceToken'));
    if(this.state.logged_in){
      fetch('/api/current_user/', { headers:
      //fetch('http://localhost:8000/current_user/', { headers:
      //fetch('https://briantaskforce.pythonanywhere.com/current_user/', { headers:
        { Authorization: `JWT ${localStorage.getItem('taskforceToken')}`
    }
  })
  .then(res => {
    if(res.ok){
      return res.json()
    }else{
      this.setState({logged_in: false})
      localStorage.removeItem('taskforceToken');
      throw new Error(res)}
  })
  .then(json =>{
    this.setState({username: json.username });

  })
  .catch(error => {
    console.log('Error on current user retrieval');
    console.log(error);

  });

  fetch('/api/current_dash/', { headers:
  //fetch('http://localhost:8000/current_dash/', { headers:
  //fetch('https://briantaskforce.pythonanywhere.com/current_dash/', { headers:
    { Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
  }).then(res => res.json())
  .then(json => {
    if(json.embed_link){
      this.setState({
        embed_link: json.embed_link,
        title: json.title,
        pgpValue: json.pgpValue,
        targetUrl: json.targetUrl,
        showChart: true


      })
      //csv link here
      // app link here

    }






  });


  }
}
  handle_login = (e, data) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_API_URL)

   //fetch('https://159.89.159.42/token-auth/', {
   fetch('/api/token-auth/', {
   //fetch('https://briantaskforce.pythonanywhere.com/token-auth/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
    .then(res => {
    if(res.ok){
      return res.json()
    }else{

      throw new Error(res)
      }
    })
    .then(json => {
      localStorage.setItem('taskforceToken', json.token);
      this.setState({
        username: json.user.username,
        logged_in:true,
        displayed_form:'',
        embed_link: json.dashboard.embed_link,
        title: json.dashboard.title,
        pgpValue: json.dashboard.pgpValue,
        targetUrl: json.dashboard.targetUrl
      });
    })
    .catch(error => {
      console.log('Login Error');
      console.log(error);
      this.setState({
        logged_in: false
      })

    });
  };


  handle_signup = (e, data) => {
    e.preventDefault();
    fetch(process.env.REACT_APP_API_URL + '/users/', {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if(res.ok)
        return res.json()
      else
        throw new Error(res)
    })
    .then(json => {
      localStorage.setItem('taskforceToken', json.token);
      this.setState({
        logged_in: true,
        displayed_form: '',
        username: json.username
      });
    })
    .catch(error => {
      console.log('Registration Error');
      console.log(error);

  });
  };

  handle_logout = () => {
    localStorage.removeItem('taskforceToken');
    this.setState({ logged_in: false, username: '' });
  };

  // handle_loginChange = (s) => {
  //   this.setState({


  //   })

  // }

  handle_password_confirmation = (token_provided, username_provided) => {
    console.log(token_provided)
    console.log(username_provided)
    let confirm_link = 'https://manage.taskforce.app/api/confirm-password/' + token_provided + '/' + username_provided + '/'
    //let confirm_link = 'http://localhost:8000/confirm-password/' + token_provided + '/' + username_provided + '/'
    //let confirm_link = 'https://briantaskforce.pythonanywhere.com/confirm-password/' + token_provided + '/' + username_provided + '/'
    let token_payload = '{"token": "' + token_provided +'", "username": "'+ username_provided + '"}'
    fetch( confirm_link, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(token_payload)
        })
    .then(res => {
      if(res.ok)
        return res.json()
      else
        throw new Error(res)

    })
    .then(json => {
        localStorage.setItem('taskforceToken', json.jwt_token)
        this.setState({
            message: json.message,
            logged_in: true,

      });
        return "OK"
    })
    .catch(error => {
      console.log('Registration Error');
      console.log(error);
      return "There has been a problem"

  });


  }

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };


  handleDrawerOpen = () => {
    this.state.open = true;
    console.log('handle open');
    console.log(this.state.open);
  };
 handleDrawerClose = () => {
    this.state.open = false;
    console.log('handle close')
    console.log(this.state.open)
  };

  // handle_query = (e, data) => {
  //   //e.preventDefault();
  //   console.log(data)
  //   fetch('/api/create_query/', {
  //     method: 'POST',
  //     headers: {'Content-Type' : 'application/json'},
  //     body: JSON.stringify(data)
  //   })
  //   .then(res => {
  //     if(res.ok)
  //       return res.json()
  //     else
  //       return res.json()
  //   })

  // };

  handle_run_query = (e) => {
    e.preventDefault();
    fetch('/api/run_queries/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
               Authorization: `JWT ${localStorage.getItem('taskforceToken')}`,

              },

    })
    .then(res => {
      if(res.ok)
        return res.json()
      else
        res.text().then(text => {throw new Error(text)})
    })
  }



  render() {
    let form;
    switch (this.state.displayed_form){
      case 'login':
        form = <LoginForm handle_login={this.handle_login} />;
        break;
      case 'signup':
        form = <SignupForm handle_signup={this.handle_signup} />;
        break;
      default:
        form = null;
}
  return(
    <div className="App">

    <Router>
    <Switch>
        <Route exact path="/forgot">
          <ForgotPass />
        </Route>
        <Route exact path="/password-change-sucess">
            <h2>Please login <a href="/">here</a></h2>
        </Route>
        <Route path="/reset-confirm/:token/:username">
          <ConfirmPassword handle_password_confirmation={this.handle_password_confirmation} />
        </Route>
        <Route exact path='/add-query'>
        {this.state.logged_in ?
          <QueryForm /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route path='/add-query/:id'>
        {this.state.logged_in ?
          <QueryForm /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>

        <Route path='/bills/:id'>
        {this.state.logged_in?
          <BillView /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/bills/'>
        {this.state.logged_in?
          <BillList /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route path='/queries/detail/:id'>
        {this.state.logged_in?
          <QueryDetail /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route path='/queries/results/:id'>
        {this.state.logged_in?
          <QueryResultsView /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/tags'>
        {this.state.logged_in?
          <TagView /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/bills'>
        {this.state.logged_in?
          <BillList /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/bills/:id'>
        {this.state.logged_in?
          <BillView /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/queries'>
        {this.state.logged_in?
          <QueryList handle_run_query={this.handle_run_query} /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>
        <Route exact path='/results'>
        {this.state.logged_in?
          <ResultList /> : <SignIn handle_login={this.handle_login} />
        }
        </Route>

        <Route exact path="/">
        <h3>
     {this.state.logged_in ?
     //<Home embed_link={this.state.embed_link} handleDrawerClose={this.handleDrawerClose} handleDrawerOpen={this.handleDrawerOpen}  />
       <Dashboard embed_link={this.state.embed_link} showChart={this.state.showChart} title={this.state.title} username={this.state.username} logout={this.handle_logout} pgpValue={this.state.pgpValue} targetUrl={this.state.targetUrl}  /> : <SignIn handle_login={this.handle_login} /> }
    </h3>
    <div>
    {/* {this.state.logged_in ?
      <iframe  src={this.state.embed_link} style={{width:"100vh", height:"100vh"}}></iframe> :
      <h4></h4>

     } */}
    </div>
        </Route>
      </Switch>
    </Router>


    {/* <Nav
      logged_in={this.state.logged_in}
      display_form={this.display_form}
      handle_logout={this.handle_logout}
    />
    {form} */}







    </div>

  );

  }
}

export default App;
