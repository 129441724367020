import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, 
  // Link,
    useParams
  } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import ConfirmPassForm from './ConfirmPassForm'; 

async function handle_password_confirmation(token_provided, username_provided, password_provided){
    console.log(token_provided)
    console.log(username_provided)
    
    let confirm_link = 'http://localhost:8000/confirm-password/' + token_provided + '/' + username_provided + '/'
    let token_payload = '{"token": "' + token_provided +'", "username": "'+ username_provided + '"'+ '", password:"' + password_provided +'}'
    const response = await fetch( confirm_link, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(token_payload)
        })
    if(response){
      return response
    }


}
function PasswordFlow(t,u){
  handle_password_confirmation(t, u).then(res =>{
     if(res.ok){
       
     }
  })


}




const ConfirmPassword = (props) => {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { token, username } = useParams();
    console.log(token)
    console.log(username)

    
    // PasswordFlow(token,username);







    return (

      <Segment>
            <ConfirmForm token_provided={token} username_provided={username} />
      </Segment>
        

    );
  }
export default ConfirmPassword;

class ConfirmForm extends React.Component { 
    state = {
        username: this.props.username_provided,
        password: '',
        submitted: false,
        token: this.props.token_provided, 
        message: "Please enter your new password"
      };
      
      handle_confirm = (e, data) => {
        e.preventDefault();
        
        //let confirm_link = 'http://localhost:8000/confirm-password/'
        let confirm_link = 'https://briantaskforce.pythonanywhere.com/confirm-password/'
        //let token_payload = '{"token": "' + token_provided +'", "username": "'+ username_provided + '"'+ '", password:"' + password_provided +'}'
        fetch( confirm_link, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
            })
        .then(res => {
         if(res.ok){
        this.setState({
            message: 'Your password has been changed',
            submitted: true
            


        }); 

        <Redirect to="/password-change-sucess" />

         }else{
            console.log(res) 
         }
          

  
        
        });
      };
      render(){
        return (
          <div>
            <h3>{this.state.message}</h3>

            {this.state.submitted ? 
          <h2>Please login <a href="/">here</a></h2>  : <ConfirmPassForm handle_confirm={this.handle_confirm} token_provided={this.props.token_provided} username_provided={this.props.username_provided}  submitted={this.state.submitted} /> }
          </div>
    
        );
  
      }
}

// const useStyles = makeStyles((theme) => ({
//     paper: {
//       marginTop: theme.spacing(8),
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//     },
//     avatar: {
//       margin: theme.spacing(1),
//       backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//       width: '100%', // Fix IE 11 issue.
//       marginTop: theme.spacing(1),
//     },
//     submit: {
//       margin: theme.spacing(3, 0, 2),
//     },
//   }));

//   function Copyright() {
//     return (
//       <Typography variant="body2" color="textSecondary" align="center">
//         {'Copyright © '}
//         <Link color="inherit" href="https://taskforce.app/">
//           Taskforce
//         </Link>{' '}
//         {new Date().getFullYear()}
//         {'.'}
//       </Typography>
//     );
//   }





// class ConfirmPassForm extends React.Component {

    


//     state = {
//       username: this.props.username_provided,
//       password: '',
//       submitted: false,
//       token: this.props.token_provided,
//     };
//     handle_change = e => {
//       const name = e.target.name;
//       const value = e.target.value;
//       this.setState(prevstate => {
//         const newState = { ...prevstate };
//         newState[name] = value;
//         return newState;
//       });
  
//     };
//     render(){
//     const {classes} = this.props;
//       return (
//         <Segment>
//         <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <div className={classes.paper}>
//           <Avatar className={classes.avatar}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Set your password
//           </Typography>

//           <form className={classes.form} onSubmit={e => this.props.handle_confirm(e, this.state)} noValidate> 
//           <TextField
//             variant="outlined"
//             margin="normal"
//             required
//             fullWidth
//             type="hidden"
//             id="username"
//             onChange={this.handle_change}
//             label="Email Address"
//             name="username"
//             value={this.props.username_provided}
//             autoComplete="email"
//             autoFocus
//           />
//          <TextField
//             variant="outlined"
//             margin="normal"
//             required
//             fullWidth
//             type="hidden"
//             id="token"
//             onChange={this.handle_change}
//             name="token"
//             value={this.props.token_provided}
//             autoFocus
//           />
//           <TextField
//             variant="outlined"
//             margin="normal"
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             onChange={this.handle_change}
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             color="primary"
//             className={classes.submit}
//           >
//             Submit
//           </Button>
//         </form>
//       </div>
//     </Container>
//     </Segment>






// <Segment>
//       <Form> 
//       <Form onSubmit={e => this.props.handle_confirm(e, this.state)}>
//       <Form.Field>
//         <label htmlFor="password">New password</label>
//         <input
//         type="text"
//         name="password"
//         value={this.state.password}
//         onChange={this.handle_change}
//       />
//       </Form.Field>
//       <Form.Field>
//         <input
//         type="hidden"
//         name="username"
//         value={this.props.username_provided}
        
       
//       />
//       </Form.Field>
//       <Form.Field>
//         <input
//         type="hidden"
//         name="token"
//         value={this.props.token_provided}
        
      
//       />
//       </Form.Field>

//       <Button type='submit'>Submit</Button>
//     </Form>
//     </Segment>



//     )


//   }
// }
//export default withStyles(useStyles, {withTheme: true})(ConfirmPassForm)







//   class ConfirmPassword extends React.Component {
//     let token = this.props.match.token;
//     let username = this.props.match.username;
//     response = this.props.handle_password_confirmation(token,username);
//     if(response){
//         console.log(response)
//     }
//     render(){
//         return (
//             <div>
//             <h3>token: {token}</h3>
//             <h3>Usename: {username} </h3>
//           </div>



//             );
//         }

//   }

//   export default ConfirmPassword;
