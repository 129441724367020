import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { DashboardOutlined, PlayCircleFilled, ZoomIn } from '@material-ui/icons';
import { withRouter } from "react-router";
import { Button, Checkbox, Dropdown, Segment, SegmentGroup, Form, Table, Label, Menu, Icon } from 'semantic-ui-react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import DialogForm from './DialogForm'
const priority_options = [
{ key: '0', value: '0', text: 'Hide this bill.' },
{ key: '1', value: '1', text: '1' },
{ key: '2', value: '2', text: '2' },
{ key: '3', value: '3', text: '3' },
{ key: '4', value: '4', text: '4' },
{ key: '5', value: '5', text: '5' },
]

const parties=['Democratic','Republican','No Affiliation']
// const stateOptions = _.map(addressDefinitions.state, (state, index) => ({
//   key: addressDefinitions.state_abbr[index],
//   text: state,
//   value: addressDefinitions.state_abbr[index],
// }))


class QueryResultsView extends React.Component {
    state = {
        results: [],
        tags: [],
        open: false,
        priority: 1,
        selectedResult: '',
        selectedTags:[],
        selectedLatestAction:'Introduced',
        tag_options: '',
        latest_actions:[],
        parties:[],
        query:{},
        party:''
    }



    getTags = () => {
        fetch('/api/tags/', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
        })
        .then(res => {
        if(res.ok){
            return res.json();

        }
        else{
          throw new Error(res)
        }
        }).then(json => {

          this.setState({
            tags: json
          });
          var tagOptions = this.state.tags.map((tag) => ({
              key: tag.id,
              text: tag.tag_text,
              value: tag.tag_text
          }))

          this.setState({tag_options: tagOptions})

        }).catch(e =>{
            console.log(e)
        })


    }
    getLatestActions=()=>{
      fetch('/api/latest_actions/', {
        method:'GET',
        headers: { 'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
      })
      .then(res=>{
        if(res.ok){
          return res.json();
        }
        else{
          throw new Error(res);
        }
      }).then(json=> {

        this.state.latest_actions=json;
      }).catch(e=>{
        console.log(e)
      })
    }

    getParties=()=>{
      fetch('/api/parties/', {
        method:'GET',
        headers: { 'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
      })
      .then(res=>{
        if(res.ok){
          return res.json();
        }
        else{
          throw new Error(res);
        }
      }).then(json=> {
        console.log("Parties List  ",JSON.stringify(json));
        this.state.parties=json;
      }).catch(e=>{
        console.log(e)
      })
    }

    getQuery=(id)=>{
      fetch('/api/query/'+id, {
        method:'GET',
        headers: { 'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
      })
      .then(res=>{
        if(res.ok){
          return res.json();
        }
        else{
          throw new Error(res);
        }
      }).then(json=> {
        this.setState({
          query:json
        })

      }).catch(e=>{
        console.log(e)
      })
    }


    handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      console.log(newState)
      console.log('handle Change return')
      return newState;
    });


  };


    handleBillSave = () => {

    var data = {
        result: this.state.selectedResult,
        priority: this.state.priority,
        tag: this.state.selectedTag,
        party:this.state.party

    }

    fetch('/api/bills/', {
      method: 'POST',
      headers: {'Content-Type' : 'application/json',
              Authorization: `JWT ${localStorage.getItem('taskforceToken')}`

      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if(res.ok){

        alert("Bill saved!");

      }

      else
        return res.json()
    })

    }
handleBillSaveDialog = (selectedResult,priority,selectedTags,billDescription,latestAction,party) => {
    
    var data = {
        result: selectedResult,
        priority: priority,
        tagIds: selectedTags,
        description: billDescription,
        latest_action: latestAction,
        party_affiliation:party
    }

    fetch('/api/bills/', {
      method: 'POST',
      headers: {'Content-Type' : 'application/json',
              Authorization: `JWT ${localStorage.getItem('taskforceToken')}`

      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if(res.ok){
        alert("Bill saved!");
        // this.setState(initialState);

        // this.setState({
        //   redirect: true
        // })
      }

      else
        return res.json()
    })

    }

  componentDidMount() {

	this.getTags();
  this.getLatestActions();
  this.getParties();
  var id = this.props.match.params.id;
	var fetchString='/api/queries/';
	if(id.charAt(id.length-1)=='T') {
		id=id.slice(0,-1);
		fetchString='/api/trackedqueries/';
	}
  if(id.charAt(id.length-1)=='U') {
		id=id.slice(0,-1);
		fetchString='/api/untrackedqueries/';
	}
        this.getQuery(id)
        fetch(fetchString + id, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
        })
        .then(res => {
        if(res.ok){

            return res.json();

        }
        else{
        // localStorage.removeItem('taskforceToken');
        // this.setState({
        //     logged_in: false,

        //   })
          throw new Error(res)
        }
        }).then(json => {

          this.setState({
            results: json
          });
        }).catch(e =>{
            console.log(e)
        })

    }

    render(){

      return(


         <SegmentGroup>
            <Segment>
            <Menu>
              <Menu.Item href='/queries'>
              <Button basic color='black'> View Queries </Button>
              </Menu.Item>
              <Menu.Item href='/add-query'>
                <Button basic color='blue'>Add A Query</Button>
              </Menu.Item>
                            <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item href='/bills'>
                <Button basic color='blue'>Bills</Button>
              </Menu.Item>
            </Menu>

            </Segment>

            <Segment>
            <b>Query Name:{this.state.query.query_name}</b>
            </Segment>

            <Segment>
      <Table celled>
        <Table.Header>
        <Table.Row>

	     <Table.Cell>
	      Jurisdiction
	     </Table.Cell>
             <Table.Cell>
               Identifier
             </Table.Cell>
             <Table.Cell>
               Title
             </Table.Cell>
             <Table.Cell>
             Session
             </Table.Cell>
             <Table.Cell>
             Latest Action
             </Table.Cell>
             <Table.Cell>
             Latest Action Desc
             </Table.Cell>
             <Table.Cell>
             Latest Action Date
             </Table.Cell>
             <Table.Cell>
             Party
             </Table.Cell>
          <Table.Cell>
          OpenStates URL
          </Table.Cell>
         <Table.Cell>
           Actions
          </Table.Cell>

         <Table.Cell>
	        Tags
          </Table.Cell>

          </Table.Row>

        </Table.Header>
        <Table.Body>
        {this.state.results.length ? this.state.results.map((result, index) =>
        <Table.Row key={index}>

     	  <Table.Cell>
	  {result.jurisdiction_name}
	  </Table.Cell>
          <Table.Cell>
          {result.identifier}
          </Table.Cell>
	        <Table.Cell>
          {result.title}
          </Table.Cell>
          <Table.Cell>
          {result.session}
          </Table.Cell>
          <Table.Cell>
          {result.latest_action_prefix}
          {result.latest_action}
          </Table.Cell>

          <Table.Cell>

          {result.latest_action_description}
          </Table.Cell>
          <Table.Cell>
          {result.latest_action_date}
          </Table.Cell>
          <Table.Cell>
          {result.party_affiliation_prefix}
          {result.party_affiliation}
          </Table.Cell>
          <Table.Cell>
           <a href={result.openstates_url} target="_blank" rel="noopener noreferrer">{result.openstates_url}</a>
          </Table.Cell>

          <Table.Cell >
            <DialogForm tagOptions={this.state.tags}  handleBillSaveDialog={this.handleBillSaveDialog} selectedResult={result} latestActions={this.state.latest_actions} parties={this.state.parties} />

          </Table.Cell>
          <Table.Cell>
          {JSON.stringify(result.tags)}
	</Table.Cell>


        </Table.Row>



        ) : <Table.Row><Table.Cell>There are no results. Run a query to generate results.</Table.Cell></Table.Row> }



        </Table.Body>

      </Table>




{/*
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To save this Bill, select the priority and tags (if any).
          </DialogContentText> */}
        {/* <Form>
        <Form.Field
         name="priority"
         label="Priority"
         placeholder="Select the priority of this bill"
         selection
         onChange={this.handleChange}
         options={priority_options}
         value={this.state.priority}
         control={Dropdown}
        />
        <Form.Field
         name="tag"
         label="Tag"
         placeholder="Select the tag for this bill"
         selection
         onChange={this.handleChange}
         options={this.state.tag_options}
        value={this.state.selectedTag}
        control={Dropdown}

        />
        </Form>  */}
        {/* <form>
        <FormControl>
       <Select
          labelId="priority-select-label"
          id="priority-select"
          value={this.state.priority}
          onChange={this.handleChange}
        //   options={priority_options}
          placeholder='Select Priority'
          input={<Input />}
        >
        <option value={0}>0</option>
           <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
           <option value={4}>4</option>
            <option value={5}>5</option>
        </Select>
        </FormControl>
        <FormControl>
        <Select
          labelId="tag-select-label"
          id="tag-select"
          value={this.state.selectedTag}
          onChange={this.handleChange}
          placeholder='Select Tag'
          input={<Input />}

        >
        <option value="0" >Select a Tag</option>
         {this.state.tags.map((tag, index) =>

            <option key={index} value={tag.tag_text}>{tag.tag_text}</option>
         )}
        </Select>
        </FormControl>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleBillSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}

  </Segment>
  {/* <Segment>
  <DialogForm />
  </Segment>  */}
            </SegmentGroup>

      )




    }







}
export default withRouter(QueryResultsView)
