
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Segment } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));


class ForgotPassForm extends React.Component {


  state = {
    username: '',
    password: '',
    submitted: false
  };
  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });

  };
  render(){
    const {classes} = this.props;
    return (
        <Segment>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>

          {/* <Typography component="h1" variant="h5">

          </Typography> */}

          <form className={classes.form} onSubmit={e => this.props.handle_reset(e, this.state)} noValidate> 
       
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="username"
            label="Username"
            type="text"
            id="username"
            autoComplete="username"
            autoFocus
            onChange={this.handle_change}
            value={this.state.username}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
    </Segment>
    





//     <Segment>
//     <Form onSubmit={e => this.props.handle_reset(e, this.state)}>
//     <Form.Field>
//       <label htmlFor="username">Username</label>
//       <input
//       type="text"
//       name="username"
//       value={this.state.username}
//       onChange={this.handle_change}
//     />
//     </Form.Field>
//     <Button type='submit'>Submit</Button>
//   </Form>
//   </Segment>
  )


}
}

export default withStyles(useStyles, {withTheme: true})(ForgotPassForm)