import React, { useState, useEffect } from "react"; 
import PropTypes from 'prop-types';
import { Button, Checkbox, Segment, SegmentGroup, Form, Table, Label, Menu, Icon, Link } from 'semantic-ui-react'



class ResultList extends React.Component { 
    state = {
        results: [],
        no_values: false
    
    }
    componentDidMount() {
        fetch('/api/list_results/', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('taskforceToken')}`}
        })
        .then(res => {
        if(res.ok){
            console.log(res)
            return res.json();  
            
        } 
        else{
        // localStorage.removeItem('taskforceToken');
        // this.setState({
        //     logged_in: false,

        //   })
          throw new Error(res)
        }
        }).then(json => {
          console.log(json)
          this.setState({
            results: json
          });
        }).catch(e =>{
            console.log(e)
        })

    }

    render(){
        return( 
            <SegmentGroup>
            <Segment> 
            <Menu>
              <Menu.Item href='/queries'>
              <Button basic color='black'> View Queries </Button>
              </Menu.Item>
              <Menu.Item href='/add-query'>
                <Button basic color='blue'>Add A Query</Button>
              </Menu.Item>
            </Menu>
            </Segment>

            <Segment>
           
            </Segment>
            
            <Segment> 
      <Table celled>
        <Table.Header>
        <Table.Row>
             <Table.Cell>
               Identifier
             </Table.Cell>
             <Table.Cell>
               Title
             </Table.Cell>
             <Table.Cell>
               Jurisdiction
             </Table.Cell>
             <Table.Cell>
               Organization
             </Table.Cell>
             <Table.Cell>
              Session
          </Table.Cell>
          <Table.Cell>
          OpenStates URL 
          </Table.Cell>
          </Table.Row>

        </Table.Header>
        <Table.Body>
       
          { this.state.results.length ? this.state.results.map((result, index) => 
        <Table.Row key={index}> 
          <Table.Cell>
          {result.identifier}
          </Table.Cell>
          <Table.Cell>
          {result.title}
          </Table.Cell>
          <Table.Cell>
            {result.jurisdiction_classification} - {result.jurisdiction_name}  
          </Table.Cell>
          <Table.Cell>
          {result.from_organization_name}
          </Table.Cell>
          <Table.Cell>
          {result.session}
          </Table.Cell>
          <Table.Cell>
           <a href={result.openstates_url}>{result.openstates_url}</a> 
          </Table.Cell>
        </Table.Row>

        
        
        ) : <Table.Row><Table.Cell>There are no results. Run a query to generate results.</Table.Cell></Table.Row> } 



        </Table.Body>

      </Table>
      

      </Segment>
            </SegmentGroup>


        )


    }
    
    





}
export default ResultList