import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Divider, Button, Checkbox, Segment, SegmentGroup, Form, Table, Label, Menu, Icon, Grid, Header, Dimmer, Loader } from 'semantic-ui-react'
// import {combineValidators, composeValidators, hasLengthGreaterThan, isRequired} from 'revalidate';
import { Form as FinalForm, Field} from 'react-final-form';
import SelectInput from '@material-ui/core/Select/SelectInput';
import { withRouter } from "react-router";


// const validate = combineValidators({
//   query_name: isRequired({message: 'Query name is required'}),
//   customer: isRequired({ message: 'Name of the customer is required'}),




// })
const initialState = {
  jurisdiction: '',
  session: '',
  chamber: '',
  classification: '',
  subject: '',
  updated_since: '',
  created_since: '',
  action_since: '',
  sponsor: '',
  sponsor_classification: '',
  q: '',
  include: '',
  page: '1',
  per_page: '20',
  query_name: '',
  customer: '',
  sort: 'updated_desc',
  results: [],
  numberOfResults: '',
  preview: false,
  spinner: false

}

const required = value => (value ? undefined : 'This field is required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

class QueryForm extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {value: 'coconut'};


this.handleArrayChange = this.handleArrayChange.bind(this);

  }



  state = {
    jurisdiction: '',
    session: '',
    chamber: '',
    classification: '',
    subject: '',
    updated_since: '',
    created_since: '',
    action_since: '',
    sponsor: '',
    sponsor_classification: '',
    q: '',
    include: '',
    page: '1',
    per_page: '20',
    query_name: '',
    customer: '',
    sort: 'updated_desc',
    results: [],
    numberOfResults: '',
    preview: false,
    redirect: false,
    spinner:false

  };
  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });

  };

  handle_query = (e, data) => {
    //e.preventDefault();
    data['results']=[]
    //console.log(data);
    var method='POST';
    var url='/api/create_query/';
    if (data['id']!=undefined) {
      method='PUT';
      url='/api/edit_query/' + data.id;
    }
    fetch(url, {
      method: method,
      headers: {'Content-Type' : 'application/json',
              Authorization: `JWT ${localStorage.getItem('taskforceToken')}`

      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if(res.ok){
        alert("Query saved!");
        this.setState(initialState);

        // this.setState({
        //   redirect: true
        // })
      }

      else
        return res.json()
    })

  };

  handle_reset = () => {

    this.setState(initialState);


  }
  handle_edit = (e, data) => {
    this.setState({
      results:  [],
      preview: false,
      numberOfResults: ''
    })
  }

  handleArrayChange(event) {
    //this.setState({value: event.option});

    this.setState({value: Array.from(event.target.selectedOptions, (item) => item.value)});
  }

  handle_preview = (e, data) =>{
    e.preventDefault();
    this.setState({spinner:true})
    fetch('/api/preview/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `JWT ${localStorage.getItem('taskforceToken')}`},
        body: JSON.stringify(data)
  }).then(res=>{
  if(res.ok){
    return res.json();
  }else {
    throw Error(res);
    }
  }).then(json => {
    var resp = JSON.parse(json)
    this.setState({
      results: resp.results,
      preview: true,
      spinner:false,
      numberOfResults: resp.total_results
    })
  })



  }

  componentDidMount() {

    const id = this.props.match.params.id;
    //console.log("id " + JSON.stringify(this.props.match));
    if(id!=undefined) {
      fetch('/api/query/' + id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                  Authorization: `JWT ${localStorage.getItem('taskforceToken')}`


        },
      }
      ).then(res => {

      if(res.ok)
        return res.json();
      else{
      //   localStorage.removeItem('taskforceToken');
      //   this.setState({
      //     logged_in: false,

      //   })
        //should push back to login
        throw new Error(res)
      }
      }).then(json => {
        //console.log("My json " +JSON.stringify(json))
        this.setState({
          id:json.id,
          jurisdiction: json.jurisdiction,
          session: json.session,
          chamber: json.chamber,
          classification: json.classification,
          subject: json.subject,
          updated_since: json.updated_since,
          created_since: json.created_since,
          action_since: json.action_since,
          sponsor: json.sponsor,
          sponsor_classification: json.sponsor_classification,
          q: json.q,
          include: json.include,
          page: json.page,
          per_page: json.per_page,
          query_name: json.query_name,
          customer: json.customer,
          sort: json.sort,
          results: [],
          numberOfResults: '',
          preview: false,
          redirect: false,
          spinner:false

        });
        //console.log("state " + JSON.stringify(state));

      })
      .catch(error => {
        console.log('Load Error');
        console.log(error);

      });
    }


  };




  render(){


  // if(this.state.redirect){
  //   <Redirect to="/queries" />
  // }

  if(!this.state.preview){
    if(this.state.spinner){
    return(
    <SegmentGroup>
        <Segment>
        <Menu>
          <Menu.Item>
      		<Button basic color='blue' type='button' onClick={e => this.handle_preview(e, this.state)}>Preview Results</Button>
          </Menu.Item >
          <Menu.Item href='/queries'>
            <Button  basic color='blue' type='button'>Manage Queries</Button>
          </Menu.Item>
                <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item>
      		<Button basic color='blue' type='Button' onClick={e => this.handle_query(e, this.state)}>Save this Query</Button>
              </Menu.Item>
        </Menu>
    </Segment>
    <Segment>
    <Divider hidden/>
    <Divider hidden/>
    <Divider hidden/>
      <Dimmer active inverted>
        <Loader size='medium'>Loading</Loader>
      </Dimmer>
    </Segment>
    </SegmentGroup>
    )
    }else{



    return(
    <SegmentGroup>
    <Segment>
        <Menu>
          <Menu.Item>
      		<Button basic color='blue' type='button' onClick={e => this.handle_preview(e, this.state)}>Preview Results</Button>
          </Menu.Item >
          <Menu.Item href='/queries'>
            <Button  basic color='blue' type='button'>Manage Queries</Button>
          </Menu.Item>
                        <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item>
      		<Button basic color='blue' type='Button' onClick={e => this.handle_query(e, this.state)}>Save this Preview Query</Button>
              </Menu.Item>
        </Menu>






    </Segment>

    <Segment>
      <FinalForm
        // validate={validate}
        onSubmit={e => this.handle_query(e, this.state)}
        initialValues={this.state}
        render={({handleSubmit, invalid, pristine})=>(


      <Form onSubmit={handleSubmit}>

      <Field
        name="query_id"

        component="input"
        value={this.state.id}
        onChange={this.handle_change}

      ></Field>
      <h4>Required Fields</h4>

      <Grid>
            <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="q">Search Query</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="q"
        placeholder='Full Text Query - exact match "solar", "wealth tax", etc.'
        component="input"
        value={this.state.id}
        onChange={this.handle_change}
        validate={required}
      >
      {({ input, meta }) => (
              <div>
                <input {...input}  onChange={this.handle_change} value={this.state.q} placeholder='Full Text Query - exact match "solar", "wealth tax", etc.' type="text" />
                {meta.error && meta.touched && <span>{meta.error}</span>}
              </div>
            )}

      </Field>
            </Grid.Column></Grid.Row>
                  <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="query_name">Query Name</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="query_name"
        placeholder='The name of the query - how you will see it in our results page'
        component="input"
        value={this.state.query_name}
        onChange={this.handle_change}
        validate={required}
      >
      {({ input, meta }) => (
              <div>
                <input {...input} value={this.state.query_name} onChange={this.handle_change} placeholder='The name of the query - how you will see it in our results page' type="text" />
                {meta.error && meta.touched && <span>{meta.error}</span>}
              </div>
            )}
      </Field>


            </Grid.Column></Grid.Row>
                  <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="customer">Customer</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="customer"
        placeholder='Customer to tag the query for if an Admin.'
        component="input"
        value={this.state.customer}
        onChange={this.handle_change}
        validate={required}
      >
       {({ input, meta }) => (
              <div>
                <input {...input}  onChange={this.handle_change}  value={this.state.customer} placeholder='Customer/Project to tag the query for.' type="text" />
                {meta.error && meta.touched && <span>{meta.error}</span>}
              </div>
            )}


      </Field>
      </Grid.Column></Grid.Row>
      <Divider horizontal>
      <Header as="h5">
        Optional Fields
      </Header>
      </Divider>
      <Grid.Row><Grid.Column width={8} textAlign="right">

      <label htmlFor="jurisdiction">Jurisdiction</label></Grid.Column>
      <Grid.Column width={8}>
      <Field
        name="jurisdiction"
        placeholder="Oregon"
        component="input"
        value={this.state.jurisdiction}
        onChange={this.handle_change}
      />
      </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="session">Session</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="session"
        placeholder='2021-2022, 2020, etc '
        component="input"
        value={this.state.session}
        onChange={this.handle_change}
      />
      </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="chamber">Chamber</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="chamber"
        placeholder='Chamber of Origination: Senate, Assembly, etc.'
        component="input"
        value={this.state.chamber}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="classification">Classification</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="classification"
        placeholder='bill, resolution, etc'
        component="select"
        value={this.state.classification}
        onChange={this.handle_change}
      >
        <option />
        <option value="bill">Bill</option>
        <option value="bill">Resolution</option>

      </Field>
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="subject">subject</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="subject"
        placeholder='Subjects covered - multiple should be comma separated: "cars","solar","EV"'
        component="input"
        value={this.state.subject}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="updated_since">Updated Since</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="updated_since"
        placeholder='Date Last Updated in YYYY-MM-DD format'
        component="input"
        value={this.state.updated_since}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="created_since">Created Since</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="created_since"
        placeholder='Date Created in YYYY-MM-DD format'
        component="input"
        value={this.state.created_since}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="action_since">Action Since</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="action_since"
        placeholder='Action since this date in YYYY-MM-DD format'
        component="input"
        value={this.state.action_since}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="sponsor">Sponsor</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="sponsor"
        placeholder='sponsor'
        component="input"
        value={this.state.sponsor}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
    <label htmlFor="sponsor_classification">Sponsor Classification</label></Grid.Column><Grid.Column width={8}>
    <Field
        name="sponsor_classification"
        placeholder='sponsor classification'
        component="input"
        value={this.state.sponsor_classification}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>

      {/* <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="include">include</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="include"
        placeholder='Include'
        component="select"
        value={this.state.include}
        onChange={this.handleArrayChange}
      multiple>
        <option />
        <option value="sponsorships">Sponsorships</option>
        <option value="abstracts">Abstracts</option>
        <option value="other_titles">Other Titles</option>
        <option value="other_identifiers">Other Identifiers</option>
        <option value="actions">Actions</option>
        <option value="sources">Sources</option>
        <option value="documents">Documents</option>
        <option value="versions">Versions</option>
        <option value="votes">Votes</option>

      </Field>
            </Grid.Column></Grid.Row>  */}
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="page">page</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="page"
        placeholder='1'
        component="input"
        value={this.state.page}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>
      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="per_page">Per page</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="per_page"
        placeholder='results per page; 20 is max.'
        component="input"
        value={this.state.per_page}
        onChange={this.handle_change}
      />
            </Grid.Column></Grid.Row>


      <Grid.Row><Grid.Column width={8} textAlign="right">
      <label htmlFor="sort">Sort</label></Grid.Column><Grid.Column width={8}>
      <Field
        name="sort"
        placeholder='Sort of the results'
        component="select"
        value={this.state.sort}
        onChange={this.handle_change}
      >
        <option value="updated_desc">Updated Descending</option>
        <option value="updated_asc">Updated Ascending</option>
        <option value="first_action_desc">First Action Descending</option>
        <option value="first_action_asc">First Action Ascending</option>
        <option value="updated_desc">Latest Action Descending</option>
        <option value="updated_desc">Latest Action Ascending</option>


      </Field>
      </Grid.Column></Grid.Row>
      </Grid>
      <Divider hidden />

      </Form>


      )}

    />

    </Segment>
    </SegmentGroup>
    )
    }
  }
  else{

    return(
      <SegmentGroup>
      <Segment>
          <Menu>
            <Menu.Item href='/results'>
                View Results
            </Menu.Item>
            <Menu.Item>
              <Button basic color='green' type='button' onClick={e => this.handle_edit(e, this.state)}>Edit This Query</Button>
            </Menu.Item>
            <Menu.Item>
              <Button basic color='yellow' type='button' onClick={this.handle_reset}>Reset the form</Button>
            </Menu.Item>
            <Menu.Item>
              <Button basic color='blue' type='button' onClick={e => this.handle_query(e, this.state)}>Save This Query</Button>
            </Menu.Item>
                          <Menu.Item href='/tags'>
                <Button basic color='blue'>Issue Tags</Button>
              </Menu.Item>
              <Menu.Item href='/bills'>
                <Button basic color='blue'>Bills</Button>
              </Menu.Item>
          </Menu>






      </Segment>
      <Segment>
      <h3>Total Results: {this.state.numberOfResults} for query {this.state.q}</h3>
      </Segment>
    <Segment>
      <Table celled>
        <Table.Header>
          <Table.Row>
             <Table.Cell>
               Identifier
             </Table.Cell>
             <Table.Cell>
               Title
             </Table.Cell>
             <Table.Cell>
               Jurisdiction
             </Table.Cell>
             <Table.Cell>
               Organization
             </Table.Cell>
             <Table.Cell>
              Session
          </Table.Cell>
          <Table.Cell>
          OpenStates URL
          </Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {this.state.results.map(result => <Table.Row key={result.id}>
          <Table.Cell>
          {result.identifier}
          </Table.Cell>
          <Table.Cell>
          {result.title}
          </Table.Cell>
          <Table.Cell>
            {result.jurisdiction_classification} - {result.jurisdiction_name}
          </Table.Cell>
          <Table.Cell>
          {result.from_organization_name}
          </Table.Cell>
          <Table.Cell>
          {result.session}
          </Table.Cell>
          <Table.Cell>
            <a href={result.openstates_url} target="_blank">{result.openstates_url}</a>
          </Table.Cell>
        </Table.Row>
        )}
        </Table.Body>
      </Table>

    </Segment>
    </SegmentGroup>
  );
  }

}
}

//export default QueryForm
export default withRouter(QueryForm);
// QueryForm.propTypes = {
//   handle_query: PropTypes.func.isRequired
// };
