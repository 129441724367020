import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, {SelectChangeEvent} from '@material-ui/core/Select';
import ReactSelect from "react-select";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  longFormControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DialogForm (props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [selectedTags, setSelectedTags] = React.useState(props.selectedResult.tags)
  const [selectedTagsId, setSelectedTagsId] = React.useState([])
  const [priority, setPriority] = React.useState(1)
  const [selectedLatestAction, setSelectedLatestAction]=React.useState(props.selectedResult.latest_action)
  const [selectedParty, setSelectedParty]=React.useState(props.selectedResult.party_affiliation)
  const [latestActions,setLatestActions]=React.useState(props.latestActions)
  const [billDescription, setBillDescription]=React.useState(props.selectedResult.description)
  const [tagOptions, setTagOptions] = React.useState(props.tagOptions)
  //   const [priorityOptions, setPriorityOptions] = React.useState(props.priorityOptions)
  const [selectedResult, setSelectedResult] = React.useState(props.selectedResult)



  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  const handleTagChange = (event) => {
    console.log('Value '+JSON.stringify(event.target.value));
    setSelectedTagsId(event.target.value);

  };
  const handleBillDescriptionChange=(event) => {
    setBillDescription(event.target.value);
  }
  const handleClickOpen = () => {

    setSelectedTagsId(selectedTags.map(({id})=>id));

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    props.handleBillSaveDialog(selectedResult, priority, selectedTagsId, billDescription, selectedLatestAction, selectedParty);
    setOpen(false);
  };
  const handleLatestActionChange=(event)=> {
    setSelectedLatestAction(event.target.value);
  }
  const handlePartyChange=(event)=> {
    setSelectedParty(event.target.value);
  }

if(props.tagOptions){
  return (
    <div>
      <Button onClick={handleClickOpen}>Save This Bill</Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Choose the tag and priority for this bill.</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Priority</InputLabel>
              <Select
                native
                value={priority}
                onChange={handlePriorityChange}
                input={<Input id="demo-dialog-native" />}
              >
            <option value={0}>Hide This Bill</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>

              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="tags-select-input">Tags</InputLabel>
              <Select
                labelId="tags-select-label"

                id="tags-select"
                multiple
                value={selectedTagsId}
                onChange={handleTagChange}
                renderValue={(selected)=>selected.join(', ')}
                input={<Input label ="Tags" />}
              >
              {props.tagOptions.map((tag)=>(
                <MenuItem value={tag['id']} >
                  <Checkbox checked={selectedTagsId.indexOf(tag['id']) > -1}/>

                  {tag['tag_text']}
                </MenuItem>
              ))}

              </Select>

            </FormControl>
            </form>
            <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="latest-actions-select-label">Latest Action</InputLabel>
              <Select
                labelId="latest-actions-select-label"

                id="latest_actions_select"
                value={selectedLatestAction}
                onChange={handleLatestActionChange}
                input={<Input />}
              >
              {props.latestActions.map((action) =>

              <MenuItem value={action}>{action}</MenuItem>
              )}


              </Select>

            </FormControl>
          </form>

          <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel id="party-select-label">Party Affiliation</InputLabel>
            <Select
              labelId="party-select-label"
              id="party_select"
              value={selectedParty}
              onChange={handlePartyChange}
              input={<Input />}
            >
            {props.parties.map((party) =>

            <MenuItem value={party}>{party}</MenuItem>
            )}


            </Select>

          </FormControl>
        </form>

          <form className={classes.container}>
          <FormControl className={classes.longFormControl}>
          <TextField
          id="standard-multiline-static"
          label="Description"
          multiline
          rows={4}
          defaultValue=""
          value={billDescription}
          onChange={handleBillDescriptionChange}
          />
          </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}else{
    return(
    <div>
    <Button>Loading...</Button>
    </div> )
}
}

// export default withStyles(useStyles, {withTheme: true})(DialogForm)
